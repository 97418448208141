@font-face {
font-family: 'mavenPro';
src: url(/_next/static/media/8fddee08bfb5a474-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'mavenPro';
src: url(/_next/static/media/6ed29d8e6fa9b3cb-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'mavenPro';
src: url(/_next/static/media/c13606b3bb9e9de8-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: 'mavenPro';
src: url(/_next/static/media/c3fb8e0b0f4dd658-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: 'mavenPro';
src: url(/_next/static/media/15669605f507d96d-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 800;
font-style: normal;
}

@font-face {
font-family: 'mavenPro';
src: url(/_next/static/media/c32dcd2c2fc876eb-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 900;
font-style: normal;
}@font-face {font-family: 'mavenPro Fallback';src: local("Arial");ascent-override: 92.88%;descent-override: 20.21%;line-gap-override: 0.00%;size-adjust: 103.90%
}.__className_7fb6b3 {font-family: 'mavenPro', 'mavenPro Fallback'
}.__variable_7fb6b3 {--font-mavenpro: 'mavenPro', 'mavenPro Fallback'
}

